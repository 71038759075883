/* About.css */

.about-content {
  display: flex;
  justify-content: center;
  border-top: 5px solid;
  border-image: linear-gradient(to right, #000000, #f8bf44, #2FBBCE, #ffffff);
  border-image-slice: 1;
  margin: 100px;
  box-shadow: 2px 6px 6px rgba(148, 148, 148);
  background-color: #ffffff;
  height: auto;
}

.about-text-container {
  width: 50%;
  padding-bottom: 20px;
  padding-left: 30px;
  text-align: justify;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.button {
  display: inline-block;
  background-color: black;
  box-shadow: 2px 6px 6px rgba(148, 148, 148);
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  border-radius: 0;
  transition: background-color 0.3s;
  width: 250px;
  height: 80px;
  cursor: pointer;
}

.button:hover {
  background: linear-gradient(to right, #000000, #f8bf44, #2FBBCE, #ffffff);
  color: black;
}

.button:focus {
  outline: none;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  .about-content {
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 0px rgba(148, 148, 148);

  }

  .about-text-container {
    width: 100%;
    text-align: center; /* Center the text within the container */
    padding: 0 15px; /* Add space on the left and right */
  }

  .container {
    width: 100%;
  }

  .button {
    width: 100%;
  }
}
