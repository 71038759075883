.header {
  background-color: #000000;
  padding: 5px;
  border-bottom: 5px solid;
  border-image: linear-gradient(to right, #000000, #f8bf44, #2FBBCE, #ffffff);
  border-image-slice: 1;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-toggle {
  cursor: pointer;
  display: none; /* Hide by default for larger screens */
  order: 2; /* Move the menu toggle to the right */
  margin-left: auto; /* Push the menu toggle to the right */
  margin-right: 15px;
  margin-top: 10px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #f8bf44;
  /* background: linear-gradient(to top, #000000, #f8bf44, #2FBBCE, #ffffff); */

  margin: 6px 0;
  transition: 0.4s;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.nav-item {
  padding: 10px;
}

.nav-link {
  text-decoration: none;
  color: #ffffff !important;
  font-weight: bolder;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}


.slick-prev:before, .slick-next:before {
  color: #2FBBCE !important;
  font-size: 25px !important;
}

@media only screen and (max-width: 768px) {
  .header{
    border: none;
  }

  .menu-toggle {
    display: block;
  }

  .nav-list {
    flex-direction: column;
    position: absolute;
    top: 70px; /* Adjust as needed */
    left: 0;
    width: 100%;
    background-color: #000000;
    display: none;
  }

  .nav-list.open {
    display: flex;
    height: -webkit-fill-available;
  }
}
