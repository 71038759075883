/* ... Previous styles ... */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    height: 80%; /* 80% of the screen height */
    max-width: 80%; /* 80% of the screen width */
    max-height: 80%; /* 80% of the screen height */
    overflow: auto; /* Enable scrolling if content overflows */
    text-align: left;
  }
  
  .close-button {
    /* ... Previous styles ... */
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
  }

  p {
    line-height: 1.5; /* Adjust the value as needed */
  }
  