/* Update your existing Footer.css or create a new CSS file if needed */

.footer {
  /* Your existing footer styles */
  background-color: black;
  padding: 10px;
  position: relative;
  border-top: 5px solid;
  border-image: linear-gradient(to right, #000000, #f8bf44, #2FBBCE, #ffffff);
  border-image-slice: 1;
}

.footer-content {
  text-align: center;
}

.footer-content p {
  color: white;
}

.scroll-button {
  position: absolute;
  top: -50px; /* Adjust this value to position the button */
  right: 15px; /* Adjust this value to position the button */
  width: 50px;
  height: 50px;
  background-color: #000000;
  color: #ffffff;
  border-color: #ffffff;
  border-style: solid;
  border-bottom: none;
  border-top-right-radius: 30%;
  border-top-left-radius: 30%;
  /* border-top-left-radius: 15%;
  border-top-right-radius: 15%; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
}

.scroll-button:hover {
  background-color: #ffffff;
  color: #000000;
}
