#projects {
  margin-top: 100px;
}

.slider-container {
  width: 83%;
  margin: 0 auto;
  border-top: 5px solid;
  border-image: linear-gradient(to right, #000000, #f8bf44, #2FBBCE, #ffffff);
  border-image-slice: 1;
  box-shadow: 2px 6px 6px rgba(148, 148, 148);
  background-color: white;
}

.slider-container h2 {
  padding-top: 15px;
}

.carousel-img {
  width: 85%;
  height: 250px;
  padding: 10px;
  margin-left: 30px;
  margin-bottom: 15px;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}

.slick-prev {
  left: 10px;
  display: none !important;
}

.slick-next {
  right: 10px;
  display: none !important;
}

.slick-dots {
  position: absolute;
  bottom: 2px;
  list-style: none;
  display: flex !important;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li button {
  font-size: 0; /* Hide the dot number */
  cursor: pointer;
  color: #000; /* Adjust the color as needed */
  opacity: 0.5; /* Adjust the opacity as needed */
  transition: opacity 0.3s ease;
  border: none; /* Remove border around dots */
  background: transparent; /* Set background to transparent */
}

.slick-dots li button:before {
  content: '•'; /* Use a bullet as the dot */
  font-size: 10px; /* Adjust the bullet size */
}

.slick-dots li.slick-active button {
  opacity: 1;
}


/* Media Queries */
@media only screen and (max-width: 768px) {
  .slider-container {
    width: 90%;
  }

  .carousel-img {
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .slick-prev,
  .slick-next {
    font-size: 20px;
  }
}
