.container1 {
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    grid-gap: 0px;
    display: flex;
    justify-content: center;
    border-top: 5px solid ;
    border-image: linear-gradient(to right, #000000, #f8bf44, #2FBBCE, #ffffff);
    border-image-slice: 1;
    /* border-radius: 5px; */
    margin: 100px;
    box-shadow: 2px 6px 6px rgba(148, 148, 148);
  }
  
  .left-div {
    flex: 0.5;
    background-color: #ffffff;
    text-align: center;
  }

  .left-div img {
    width: 100%;
    height: auto;
    padding-left: 5px;
    padding-top: 5px;
  }
  .right-div {
    flex: 1;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
  }
  

  /* Media Queries */
@media only screen and (max-width: 768px) {
  .container1 {
    display: block;
    margin: 20px; /* Adjust as needed */
    box-shadow: 0px 0px 0px rgba(148, 148, 148);

  }

  .left-div {
    flex: 1;
    text-align: center;
  }

  .left-div img {
    width: 100%; /* Make the image span from right to left */
    height: auto;
    padding: 0 0 0 0;
  }

  .right-div {
    flex: 1;
    font-size: 18px; /* Adjust as needed */
  }
}