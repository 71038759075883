.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: 5px solid;
  border-image: linear-gradient(to right, #000000, #f8bf44, #2FBBCE, #ffffff);
  border-image-slice: 1;
  /* border-radius: 5px; */
  margin: 100px;
  margin-top: 10px !important;
  box-shadow: 2px 6px 6px rgba(148, 148, 148);
  background-color: #ffffff;
}

/* Contact Header */
.contact-header {
  flex-basis: 100%;
  text-align: center;
}

/* Contact Content */
.contact-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 5%;
  padding: 0 10px 25px 10px;
  margin-top: 20px;
}



/* Contact Form */
.contact-form {
  flex-basis: 60%;
}

/* Contact Info */
.contact-info {
  margin-left: 20px;
}


.input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.text-box input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #000000;
  margin-top: 15px;
  margin-bottom: 15px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.text-area {
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 2px 6px 6px rgba(148, 148, 148);
  resize: none !important;
  padding: 10px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.button-submit {
  display: inline-block;
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 0;
  transition: background-color 0.3s;
  width: 100%;
  height: 45px;
  box-shadow: 2px 6px 6px rgba(148, 148, 148);
  margin-bottom: 10px;
  cursor: pointer;
}

.button-submit:hover {
  background: linear-gradient(to right, #000000, #f8bf44, #2FBBCE, #ffffff);
  color: black;
}

.button-submit:focus {
  outline: none;
}

.icon-style {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  gap: 35px;
}

/* .shadow {
  padding-left: 25px;
} */

.icon-link {
  color: inherit;
  text-decoration: none;
}

.icon-link:hover {
  color: inherit;
}

.p {
  font-weight: bold;
  text-align: left;
}


/* Media Queries */
@media only screen and (max-width: 768px) {
  .contact-container {
    margin: 20px;
    margin-bottom: 80px;
    box-shadow: none;

  }

  .contact-content {
    padding: 0 10px 25px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-form {
    flex-basis: 100%;
  }

  .icon-style {
    margin-top: 20px;
    gap: 20px;
  }
}